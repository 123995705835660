<template>
  <div>
    <v-list-item :to="{ name: `trainerSessionsPending` }">
      <v-list-item-icon>
        <img height="20" width="20" src="@/assets/icons/training-courses.svg" alt="Dashboard" />
      </v-list-item-icon>
      <v-list-item-title>Sesiones</v-list-item-title>
    </v-list-item>

    <v-list-item :to="{ name: 'trainerFinishedInscriptions' }">
      <v-list-item-icon>
        <img height="20" width="20" src="@/assets/icons/leads.svg" alt="Dashboard" />
      </v-list-item-icon>
      <v-list-item-title>Evaluaciones</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style scoped></style>
